var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "gov-width-container",
    [
      _c("gov-back-link", { attrs: { to: { name: "referrals-index" } } }, [
        _vm._v("Back to referrals")
      ]),
      _c(
        "gov-main-wrapper",
        [
          _vm.loadingReferral
            ? _c("ck-loader")
            : [
                _c("vue-headful", {
                  attrs: {
                    title: _vm.appName + " - Referral: " + _vm.referral.name
                  }
                }),
                _c(
                  "gov-grid-row",
                  [
                    _c(
                      "gov-grid-column",
                      { attrs: { width: "two-thirds" } },
                      [
                        _c("gov-heading", { attrs: { size: "m" } }, [
                          _vm._v("Referral details")
                        ]),
                        _c("ck-referral-details", {
                          attrs: { referral: _vm.referral }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c("gov-section-break", { attrs: { size: "l" } }),
                _vm.auth.canEdit("referral", _vm.referral)
                  ? _c(
                      "gov-grid-row",
                      { attrs: { width: "two-thirds" } },
                      [
                        _c(
                          "gov-grid-column",
                          { attrs: { width: "two-thirds" } },
                          [
                            _c("gov-heading", { attrs: { size: "m" } }, [
                              _vm._v("Update case")
                            ]),
                            _c("gov-hint", [
                              _vm._v(
                                "\n            You can change the status of the referral to 'Complete',\n            'Incomplete' or 'In progress' and add a comment.\n          "
                              )
                            ]),
                            _c("ck-select-input", {
                              attrs: {
                                id: "status",
                                label: "Status",
                                options: _vm.statusOptions,
                                error: _vm.form.$errors.get("status")
                              },
                              on: {
                                input: function($event) {
                                  return _vm.form.$errors.clear("status")
                                }
                              },
                              model: {
                                value: _vm.form.status,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "status", $$v)
                                },
                                expression: "form.status"
                              }
                            }),
                            _c("ck-textarea-input", {
                              attrs: {
                                id: "comments",
                                label: "Comments",
                                error: _vm.form.$errors.get("comments")
                              },
                              on: {
                                input: function($event) {
                                  return _vm.form.$errors.clear("comments")
                                }
                              },
                              model: {
                                value: _vm.form.comments,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "comments", $$v)
                                },
                                expression: "form.comments"
                              }
                            }),
                            _vm.form.$submitting
                              ? _c(
                                  "gov-button",
                                  { attrs: { disabled: "", type: "submit" } },
                                  [_vm._v("Updating...")]
                                )
                              : _c(
                                  "gov-button",
                                  {
                                    attrs: { type: "submit" },
                                    on: { click: _vm.onSubmit }
                                  },
                                  [_vm._v("Update")]
                                ),
                            _vm.form.$errors.any()
                              ? _c("ck-submit-error")
                              : _vm._e()
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _c("gov-section-break", { attrs: { size: "l" } }),
                _c(
                  "gov-grid-row",
                  [
                    _c(
                      "gov-grid-column",
                      { attrs: { width: "full" } },
                      [
                        _c("gov-heading", { attrs: { size: "m" } }, [
                          _vm._v("Previous comments")
                        ]),
                        _vm.loadingStatusUpdates
                          ? _c("ck-loader")
                          : [
                              _vm._l(_vm.statusUpdates, function(
                                statusUpdate,
                                key
                              ) {
                                return _c(
                                  "gov-grid-row",
                                  { key: key },
                                  [
                                    _c(
                                      "gov-grid-column",
                                      { attrs: { width: "full" } },
                                      [
                                        _c(
                                          "gov-grid-row",
                                          [
                                            _c(
                                              "gov-grid-column",
                                              {
                                                attrs: { width: "two-thirds" }
                                              },
                                              [
                                                _c("gov-body", [
                                                  _vm._v(
                                                    _vm._s(
                                                      statusUpdate.user
                                                        .first_name
                                                    ) +
                                                      "\n                      " +
                                                      _vm._s(
                                                        statusUpdate.user
                                                          .last_name
                                                      )
                                                  )
                                                ]),
                                                _c("gov-body", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.formatDateTime(
                                                        statusUpdate.created_at
                                                      )
                                                    )
                                                  )
                                                ])
                                              ],
                                              1
                                            ),
                                            _c(
                                              "gov-grid-column",
                                              {
                                                staticClass: "text-right",
                                                attrs: { width: "one-third" }
                                              },
                                              [
                                                _c("gov-tag", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm._f("status")(
                                                        statusUpdate.to
                                                      )
                                                    )
                                                  )
                                                ])
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        statusUpdate.comments
                                          ? _c(
                                              "gov-grid-row",
                                              [
                                                _c(
                                                  "gov-grid-column",
                                                  { attrs: { width: "full" } },
                                                  [
                                                    _c("gov-body", [
                                                      _vm._v(
                                                        _vm._s(
                                                          statusUpdate.comments
                                                        )
                                                      )
                                                    ])
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _c("gov-section-break", {
                                          attrs: { size: "l", visible: "" }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              }),
                              _c(
                                "gov-body",
                                [
                                  _vm._v(
                                    "\n              Page " +
                                      _vm._s(_vm.currentPage) +
                                      " of " +
                                      _vm._s(_vm.lastPage) +
                                      "\n              "
                                  ),
                                  _vm.currentPage > 1
                                    ? _c(
                                        "gov-link",
                                        { on: { click: _vm.onPrevious } },
                                        [_vm._v("Back")]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.currentPage < _vm.lastPage
                                    ? _c(
                                        "gov-link",
                                        { on: { click: _vm.onNext } },
                                        [_vm._v("Next")]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ]
                      ],
                      2
                    )
                  ],
                  1
                ),
                _vm.auth.canDelete("referral")
                  ? [
                      _c("gov-section-break", { attrs: { size: "l" } }),
                      _c(
                        "gov-grid-row",
                        { attrs: { width: "two-thirds" } },
                        [
                          _c(
                            "gov-grid-column",
                            { attrs: { width: "two-thirds" } },
                            [
                              _c("gov-heading", { attrs: { size: "m" } }, [
                                _vm._v("Delete referral")
                              ]),
                              _c("gov-body", [
                                _vm._v(
                                  "Please be certain of the action before deleting a\n              referral"
                                )
                              ]),
                              _c("gov-section-break", { attrs: { size: "l" } }),
                              _c("ck-delete-button", {
                                attrs: {
                                  resource: "referral",
                                  endpoint: "/referrals/" + this.referral.id
                                },
                                on: { deleted: _vm.onDelete }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  : _vm._e()
              ]
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }